/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Box } from "flicket-ui";
import Select, { components, GroupType, OptionProps } from "react-select";
import { useTheme } from "styled-components";
import useLayoutContext from "../../../hooks/useLayoutContext";
import { allPackages, isAllPackages, PackageOptionType } from "../util";
import { CommonDisplay, defaultComponents, selectStyles } from "./common";
import { SearchItem } from "../SearchItem";
import { PRIMARY_NAVIGATION_KEYS } from "~features/reports/reporting/navigation/primary.config";
import { Skeleton } from "~components";
import { SourceSelection } from "~features/reports/reporting/navigation/useNavigation";

interface PackageSelectProps {
  onChange: (option: SourceSelection) => void;
  modal?: boolean;
}

export const Option = (props: OptionProps<PackageOptionType, false>) => {
  return (
    <components.Option {...props}>
      {isAllPackages(props.data) ? (
        <SearchItem active={props.isFocused}>All packages</SearchItem>
      ) : (
        <SearchItem active={props.isFocused}>{props.data.name}</SearchItem>
      )}
    </components.Option>
  );
};

function Subtitle() {
  const context = useLayoutContext();

  if (context.pageState === "layoutLoading") {
    return <Skeleton height={15} maxWidth={"400px"} mt={"6/4"} />;
  }

  if (context.type !== PRIMARY_NAVIGATION_KEYS.PACKAGES) return null;

  return <Box mt={1}>package subtitle</Box>;
}

export function PackageDisplay({ small }: { small?: boolean }) {
  const context = useLayoutContext();

  if (context.type !== PRIMARY_NAVIGATION_KEYS.PACKAGES) {
    return null;
  }

  let title;

  if (context.isAllPackages) {
    title = allPackages.label;
  } else {
    title = "package title";
  }

  return (
    <CommonDisplay
      small={small}
      title={title}
      subtitle={<Subtitle />}
      sourceLabel={context.type}
      image={null}
    />
  );
}

export default function PackageSelect(props: PackageSelectProps) {
  const theme = useTheme();

  const options: (PackageOptionType | GroupType<PackageOptionType>)[] = [];

  options.push({
    type: PRIMARY_NAVIGATION_KEYS.PACKAGES,
    label: "All packages",
    id: "all-packages",
  });

  const onChange = (option: PackageOptionType) => {
    const selectedSource = isAllPackages(option)
      ? "all-packages"
      : { packageId: option.id };

    props.onChange(selectedSource);
  };

  return (
    <Select
      menuIsOpen
      autoFocus
      options={options}
      getOptionValue={(option: PackageOptionType) => {
        return option.id;
      }}
      getOptionLabel={(option: PackageOptionType) => {
        return isAllPackages(option) ? option.label : option.name;
      }}
      onChange={onChange}
      components={{ ...defaultComponents, Option }}
      styles={selectStyles(theme)}
      placeholder="Search packages"
    />
  );
}
