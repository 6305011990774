import { createContext, ReactNode, useContext, useState } from "react";
import {
  EventInformation,
  EventLayoutOutput,
  MembershipInformation,
  MembershipLayoutOutput,
  OverallLayoutOutput,
  ReportingFinancialSalesBreakdownSource,
  ReportingLayoutDocument,
  ReportingLayoutInput,
  ReportingLayoutOutput,
  ReportingSearchIndexDocument,
  SeasonInformation,
  SeasonLayoutOutput,
} from "~graphql/typed-document-nodes";
import { useQuery } from "~hooks/useQuery";
import { DateRange } from "../types";
import {
  SearchEvent,
  SearchMembership,
  SearchSeason,
} from "../components/SourceSwitcher/interfaces";
import useNavigation, {
  isEventSource,
  isMembershipSource,
  isOverallSource,
  isPackageSource,
  isSeasonSource,
  SourceSelection,
  SpecificEventSourceSelection,
  SpecificMembershipSourceSelection,
  SpecificSeasonSourceSelection,
} from "../navigation/useNavigation";
import { PRIMARY_NAVIGATION_KEYS } from "../navigation/primary.config";
import Router, { useRouter } from "next/router";

interface LayoutContext {
  type: PRIMARY_NAVIGATION_KEYS;
  pageState: "layoutLoading" | "loaded" | "empty";
  searchIndexLoading: boolean;
  searchableEvents: SearchEvent[];
  searchableSeasons: SearchSeason[];
  searchableMemberships: SearchMembership[];
  defaultDateRange: DateRange;
}

export interface EventLayoutContext extends LayoutContext {
  type: PRIMARY_NAVIGATION_KEYS.EVENTS;
  eventInformation?: EventInformation;
  eventId?: string;
  isAllEvents: boolean;
  isAllMemberships: false;
  isDefaultEvent: boolean;
  isAllSeasons: false;
  isAllPackages: false;
}

export interface MembershipLayoutContext extends LayoutContext {
  type: PRIMARY_NAVIGATION_KEYS.MEMBERSHIPS;
  membershipInformation?: MembershipInformation;
  isAllMemberships: boolean;
  isDefaultMembership: boolean;
  membershipId?: string;
  isAllEvents: false;
  isAllSeasons: false;
  isAllPackages: false;
}

export interface SeasonLayoutContext extends LayoutContext {
  type: PRIMARY_NAVIGATION_KEYS.SEASONS;
  seasonInformation?: SeasonInformation;
  isAllSeasons: boolean;
  isDefaultSeason: boolean;
  seasonId?: string;
  isAllEvents: false;
  isAllMemberships: false;
  seasonSource: ReportingFinancialSalesBreakdownSource;
  setSeasonSource: (
    seasonSource: ReportingFinancialSalesBreakdownSource
  ) => void;
  isAllPackages: false;
}

export interface OverallLayoutContext extends LayoutContext {
  type: PRIMARY_NAVIGATION_KEYS.OVERALL;
  isAllMemberships: false;
  isAllEvents: false;
  isAllSeasons: false;
  isAllPackages: false;
}

export interface PackageLayoutContext extends LayoutContext {
  type: PRIMARY_NAVIGATION_KEYS.PACKAGES;
  isAllMemberships: false;
  isAllEvents: false;
  isAllSeasons: false;
  isAllPackages: boolean;
}

export type ReportingLayoutContext =
  | EventLayoutContext
  | MembershipLayoutContext
  | SeasonLayoutContext
  | OverallLayoutContext
  | PackageLayoutContext;

export const LayoutContext = createContext<ReportingLayoutContext>(null);

export default function useLayoutContext(): ReportingLayoutContext {
  return useContext(LayoutContext);
}

function isEventLayout(
  layout?: ReportingLayoutOutput
): layout is EventLayoutOutput {
  return layout?.__typename === "EventLayoutOutput";
}

function isMembershipLayout(
  layout?: ReportingLayoutOutput
): layout is MembershipLayoutOutput {
  return layout?.__typename === "MembershipLayoutOutput";
}

function isSeasonLayout(
  layout?: ReportingLayoutOutput
): layout is SeasonLayoutOutput {
  return layout?.__typename === "SeasonLayoutOutput";
}

function isOverallLayout(
  layout?: ReportingLayoutOutput
): layout is OverallLayoutOutput {
  return layout?.__typename === "OverallLayoutOutput";
}

function isPackageLayout(
  layout?: ReportingLayoutOutput
): layout is EventLayoutOutput {
  return layout?.__typename === "PackageLayoutOutput";
}

export function isEventLayoutContext(
  layoutContext: ReportingLayoutContext
): layoutContext is EventLayoutContext {
  return layoutContext.type === PRIMARY_NAVIGATION_KEYS.EVENTS;
}

export function isMembershipLayoutContext(
  layoutContext: ReportingLayoutContext
): layoutContext is MembershipLayoutContext {
  return layoutContext.type === PRIMARY_NAVIGATION_KEYS.MEMBERSHIPS;
}

export function isSeasonLayoutContext(
  layoutContext: ReportingLayoutContext
): layoutContext is SeasonLayoutContext {
  return layoutContext.type === PRIMARY_NAVIGATION_KEYS.SEASONS;
}

export function isOverallLayoutContext(
  layoutContext: ReportingLayoutContext
): layoutContext is OverallLayoutContext {
  return layoutContext.type === PRIMARY_NAVIGATION_KEYS.OVERALL;
}

export function isPackageLayoutContext(
  layoutContext: ReportingLayoutContext
): layoutContext is PackageLayoutContext {
  return layoutContext.type === PRIMARY_NAVIGATION_KEYS.PACKAGES;
}

export const FormatReportingLayoutInput = (
  selectedSource: SourceSelection,
  seasonSource: ReportingFinancialSalesBreakdownSource
): { input?: ReportingLayoutInput } => {
  if (selectedSource === "default") {
    return null;
  }
  if (selectedSource === "all-events") {
    return { input: { allEvents: true } };
  }
  if (selectedSource === "all-memberships") {
    return { input: { allMemberships: true } };
  }
  if (selectedSource === "all-seasons") {
    return { input: { allSeasons: true, seasonSource } };
  }
  if (selectedSource === "all-packages") {
    return { input: { allPackages: true } };
  }
  if (selectedSource === "overall") {
    return { input: { isOverall: true } };
  }
  if ("membershipId" in selectedSource) {
    return { input: { membershipId: selectedSource.membershipId } };
  }
  if ("eventId" in selectedSource) {
    return { input: { eventId: selectedSource.eventId } };
  }
  if ("seasonId" in selectedSource) {
    return { input: { seasonId: selectedSource.seasonId, seasonSource } };
  }
  if ("packageId" in selectedSource) {
    return { input: { packageId: selectedSource.packageId } };
  }
  return null;
};

export const LayoutContextData = (
  routeIsInvalid: boolean,
  selectedSource: SourceSelection,
  seasonSource: ReportingFinancialSalesBreakdownSource,
  setSeasonSource: (
    seasonSource: ReportingFinancialSalesBreakdownSource
  ) => void
) => {
  const reportingLayoutInput = FormatReportingLayoutInput(
    selectedSource,
    seasonSource
  );
  const { data, isLoading } = useQuery(
    !routeIsInvalid ? ReportingLayoutDocument : null,
    reportingLayoutInput
  );

  const { data: searchIndex, isLoading: searchIndexLoading } = useQuery(
    !routeIsInvalid ? ReportingSearchIndexDocument : null
  );
  if (routeIsInvalid) {
    void Router.push(`/reporting/events/overview`);
    return null;
  }

  const layout = data?.reportingLayout;

  const defaultDateRange = layout?.defaultDateRange
    ? {
        startDate: new Date(layout.defaultDateRange.startDate),
        endDate: new Date(layout.defaultDateRange.endDate),
      }
    : undefined;

  const sharedContext: Omit<LayoutContext, "type"> = {
    searchableEvents:
      searchIndex?.reportingSearchIndex?.searchableEvents?.map((e) => ({
        type: PRIMARY_NAVIGATION_KEYS.EVENTS,
        dates: e.dates,
        id: e.id,
        title: e.title,
      })) ?? [],
    searchableSeasons:
      searchIndex?.reportingSearchIndex?.searchableSeasons?.map((s) => ({
        type: PRIMARY_NAVIGATION_KEYS.SEASONS,
        id: s.id,
        name: s.name,
        startDate: s.startDate,
      })) ?? [],
    searchableMemberships:
      searchIndex?.reportingSearchIndex?.searchableMemberships?.map((m) => ({
        type: PRIMARY_NAVIGATION_KEYS.MEMBERSHIPS,
        id: m.id,
        name: m.name,
        startDate: m.startDate,
      })) ?? [],
    pageState: isLoading ? "layoutLoading" : "loaded",
    searchIndexLoading,
    defaultDateRange,
  };

  let layoutContext: ReportingLayoutContext;
  if (isEventLayout(layout) || isEventSource(selectedSource)) {
    layoutContext = {
      ...sharedContext,
      type: PRIMARY_NAVIGATION_KEYS.EVENTS,
      eventInformation: isEventLayout(layout) && layout.event,
      isAllEvents: selectedSource === "all-events",
      isAllMemberships: false,
      isDefaultEvent: selectedSource === "default",
      eventId:
        (selectedSource as SpecificEventSourceSelection).eventId ??
        (isEventLayout(layout) && layout?.event?.id),
      isAllSeasons: false,
      isAllPackages: false,
    };
  } else if (isMembershipLayout(layout) || isMembershipSource(selectedSource)) {
    layoutContext = {
      ...sharedContext,
      type: PRIMARY_NAVIGATION_KEYS.MEMBERSHIPS,
      membershipInformation: isMembershipLayout(layout) && layout.membership,
      isAllMemberships: selectedSource === "all-memberships",
      isDefaultMembership: selectedSource === "default",
      isAllEvents: false,
      membershipId:
        (selectedSource as SpecificMembershipSourceSelection).membershipId ??
        (isMembershipLayout(layout) && layout?.membership?.id),
      isAllSeasons: false,
      isAllPackages: false,
    };
  } else if (isSeasonLayout(layout) || isSeasonSource(selectedSource)) {
    layoutContext = {
      ...sharedContext,
      type: PRIMARY_NAVIGATION_KEYS.SEASONS,
      seasonInformation: isSeasonLayout(layout) && layout.season,
      isDefaultSeason: selectedSource === "default",
      seasonId:
        (selectedSource as SpecificSeasonSourceSelection).seasonId ??
        (isSeasonLayout(layout) && layout?.season?.id),
      isAllSeasons: selectedSource === "all-seasons",
      isAllEvents: false,
      isAllMemberships: false,
      seasonSource,
      setSeasonSource,
      isAllPackages: false,
    };
  } else if (isOverallLayout(layout) || isOverallSource(selectedSource)) {
    layoutContext = {
      ...sharedContext,
      type: PRIMARY_NAVIGATION_KEYS.OVERALL,
      isAllSeasons: false,
      isAllEvents: false,
      isAllMemberships: false,
      isAllPackages: false,
    };
  } else if (isPackageLayout(layout) || isPackageSource(selectedSource)) {
    layoutContext = {
      ...sharedContext,
      type: PRIMARY_NAVIGATION_KEYS.PACKAGES,
      isAllSeasons: false,
      isAllEvents: false,
      isAllMemberships: false,
      isAllPackages: selectedSource === "all-packages",
    };
  }

  return layoutContext;
};

// used by the reporting pages
export const LayoutContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const router = useRouter();
  const seasonSource = (router.query.seasonSource
    ? router.query.seasonSource
    : ReportingFinancialSalesBreakdownSource.Event) as ReportingFinancialSalesBreakdownSource;

  const setSeasonSource = (
    seasonSource: ReportingFinancialSalesBreakdownSource
  ) => {
    void router.replace({
      pathname: router.pathname,
      query: { ...router.query, seasonSource },
    });
  };

  const { selectedSource, activePrimaryTab } = useNavigation();

  const routeIsInvalid = !activePrimaryTab;

  const layoutContext = LayoutContextData(
    routeIsInvalid,
    selectedSource,
    seasonSource,
    setSeasonSource
  );

  if (
    selectedSource === "default" &&
    (!layoutContext || layoutContext.pageState === "layoutLoading")
  ) {
    return null;
  }

  return (
    <LayoutContext.Provider value={layoutContext}>
      {children}
    </LayoutContext.Provider>
  );
};

type SourceContextProviderProps = {
  children: ReactNode;
  sourceSelection: SourceSelection;
};

// used by the export modal
export const SourceSwitcherContextProvider = ({
  children,
  sourceSelection,
}: SourceContextProviderProps) => {
  const [
    seasonSource,
    setSeasonSource,
  ] = useState<ReportingFinancialSalesBreakdownSource>(
    ReportingFinancialSalesBreakdownSource.Event
  );

  const layoutContext = LayoutContextData(
    false,
    sourceSelection,
    seasonSource,
    setSeasonSource
  );

  if (
    sourceSelection === "default" &&
    (!layoutContext || layoutContext.pageState === "layoutLoading")
  ) {
    return null;
  }

  return (
    <LayoutContext.Provider value={layoutContext}>
      {children}
    </LayoutContext.Provider>
  );
};
